<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
      @change="(val) => $emit('closeModal', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Sayfa Ayar</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <div class="p-1">
            <b-row>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Banner Alanını Göster</label>
                  <b-form-checkbox :checked="form.ayar.banner" v-model="form.ayar.banner" switch />
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Bread Crumbs Alanı Göster</label>
                  <b-form-checkbox :checked="form.ayar.bread_crumbs" v-model="form.ayar.bread_crumbs" switch />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Video Gösterimi</label>
                  <b-form-checkbox :checked="form.ayar.video_gosterim" v-model="form.ayar.video_gosterim" switch />
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Misafir Yorum Göster</label>
                  <b-form-checkbox :checked="form.ayar.misafir_yorumlari" v-model="form.ayar.misafir_yorumlari" switch />
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Ürünlerden Bazıları Göster</label>
                  <b-form-checkbox :checked="form.ayar.urunlerden_bazilari" v-model="form.ayar.urunlerden_bazilari" switch />
                </div>
              </b-col>
              <b-col cols="6">
                <div class="d-flex justify-content-between align-items-center py-1">
                  <label for="statu" class="font-weight-bold h6">Abone Ol Göster</label>
                  <b-form-checkbox :checked="form.ayar.abone_ol" v-model="form.ayar.abone_ol" switch />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-cols="5" label="Sayfa Format">
                  <v-select
                    v-model="form.ayar.sayfa_format"
                    :options="sayfaFormat"
                    :reduce="(sayfa) => sayfa.id"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select d-block"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="pt-1">
              <b-col cols="12" md="6" lg="6">
                <b-button squared block type="submit" variant="primary" @click="onSubmit">
                  <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ $t('global.guncelle') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-button squared block @click="hide">
                  <feather-icon icon="XSquareIcon" class="mr-50" />
                  <span class="align-middle">{{ $t('global.iptal') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Cleave from 'vue-cleave-component';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';

export default {
  components: {
    Cleave,
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const expo = {};
    expo.show = ref(false);
    expo.sayfaFormat = ref([
      { id: 'yazi', title: 'Sadece Yazı' },
      { id: 'yazi_resim', title: 'Yazı ve Resim' },
      { id: 'yazi_galeri', title: 'Yazı ve Galeri' },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('update', props.form);
      console.log(context);
    };
    return { ...expo };
  },

  methods: {},
};
</script>

<style lang="scss">
.dropzone {
  border: 1px solid #ebe9f0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.secilen-kapak {
  position: absolute !important;
  top: 8px;
  right: 20px;
}
.grid {
  .figcaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    width: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 1);
    img {
      opacity: 0.4;
    }
    .figcaption {
      opacity: 9;
    }
  }
}
</style>
