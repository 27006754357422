<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
      @change="(val) => $emit('closeModal', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <b-overlay :show="show" rounded="sm" no-fade>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
            <h5 class="mb-0">Sayfa Görselleri</h5>
            <div>
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <div class="p-1">
            <b-row>
              <b-col cols="12">
                <input
                  multiple
                  type="file"
                  ref="file"
                  name="galeri"
                  id="selectorImagesInput"
                  accept="image/jpg,image/jpeg,image/png,image/gif"
                  class="selector-images-input"
                  @change="handlerOnSumbited"
                />
                <div class="selector-images-button" :disabled="true" @click="handlerSelectedImages">
                  <feather-icon icon="FolderPlusIcon" size="48" v-if="!show" />
                  <b-spinner v-else />
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2 px-1" v-if="Object.keys(images).length">
              <draggable v-model="images" handle=".draggable-task-handle" @end="handlerResimSiraChange">
                <transition-group type="transition" name="flip-list" class="row" tag="div">
                  <b-col v-for="item in images" :key="item.k_no" cols="12" sm="12" md="4" lg="4">
                    <div class="grid">
                      <b-img
                        thumbnail
                        fluid
                        rounded
                        class="draggable-task-handle"
                        :class="item.kapak ? 'border-success' : ''"
                        :src="imageFile(item.gorsel)"
                        v-b-tooltip.hover.top="'Sıra değiştirme için sürükleyebilirsiniz.'"
                      />
                      <feather-icon
                        v-if="item.kapak"
                        class="text-success cursor-pointer secilen-kapak"
                        size="24"
                        icon="MonitorIcon"
                        v-b-tooltip.hover.top="'Kapak Resmi'"
                      />
                      <div class="figcaption">
                        <feather-icon
                          class="text-danger cursor-pointer mr-1"
                          size="24"
                          icon="Trash2Icon"
                          @click="handlerImageRemove(item)"
                          v-b-tooltip.hover.top="'Görsel Sil!'"
                        />
                        <feather-icon
                          v-if="!item.kapak"
                          class="text-primary cursor-pointer"
                          size="24"
                          icon="MonitorIcon"
                          @click="sayfaResimKapak(item)"
                          v-b-tooltip.hover.top="'Kapak Resmi Yap.'"
                        />
                      </div>
                    </div>
                  </b-col>
                </transition-group>
              </draggable>
            </b-row>
            <b-row v-else>
              <b-col>
                <b-alert variant="warning" class="text-center rounded-0" show>
                  <h4 class="alert-heading">Opss !!</h4>
                  <div class="alert-body">Gösterilecek Görsel bulunamadı.</div>
                </b-alert>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Cleave from 'vue-cleave-component';
import draggable from 'vuedraggable';
import store from '@/store';
import { ref, watch, computed, onMounted, toRefs } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    Cleave,
    draggable,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.images = ref([]);
    expo.visible = ref(false);
    expo.index = ref(null);
    let fileName = store.getters.getUserFirma.db;

    const { openModal, updateData } = toRefs(props);

    expo.imageFile = (val) => {
      if (val) {
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
        return `${base_url}uploads/${fileName}/sayfa/${val}`;
      }
    };

    expo.handlerSelectedImages = () => {
      const file = document.getElementById('selectorImagesInput');
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;

      Object.keys(forms).map(function (key) {
        formData.append('sayfa', forms[key]);
      });

      formData.append('k_no', updateData.value.k_no);

      await store
        .dispatch('sayfaResimEkle', formData)
        .then((res) => {
          expo.show.value = false;
          context.emit('updated', res.data.data);
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerImageRemove = (item) => {
      store.dispatch('sayfaResimSil', { ...item, sayfa_k_no: updateData.value.k_no }).then((res) => {
        if (res.data.success == true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Silme Başarılı.',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          expo.images.value = res.data.data.gorseller;
        }
      });
    };

    expo.sayfaResimKapak = (item) => {
      store.dispatch('sayfaResimKapak', { ...item, sayfa_k_no: updateData.value.k_no }).then((res) => {
        if (res.data.success === true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'Trash2Icon',
                variant: 'success',
                text: `Kapak resmi olarak ayarlandı.`,
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          this.images = res.data.data.gorseller;
        }
      });
    };

    expo.handlerResimSiraChange = () => {
      store
        .dispatch('sayfaResimSiraGuncelle', { k_no: updateData.value.k_no, gorseller: expo.images.value })
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Sira Güncelleme Başarılı.',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            this.$toast({
              component: ToastificationContent,
            });
          }
        });
    };

    expo.imgShow = (index) => {
      expo.index.value = index;
      expo.visible.value = true;
    };
    expo.handleHide = () => {
      expo.visible.value = false;
    };

    watch(
      [updateData, openModal],
      (val) => {
        expo.images.value = val[0].gorseller;
      },
      {
        deep: true,
      }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.selector-images-input {
  display: none;
}
.selector-images-button {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  // max-width: 250px;
  height: auto;
  max-height: 100px;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  padding: 55px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: darken(#dee, 9);
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
